<template>
<div id="full-node">
  <div v-if="!pageReady" class="d-flex">
    <!--<div class="text-center mb-3 d-flex justify-content-between">
      <b-spinner
        v-for="variant in variants"
        :variant="variant"
        :key="variant"
      ></b-spinner>
    </div>-->

    <div class="text-center d-flex justify-content-between">
      <b-spinner
        v-for="variant in variants"
        :variant="variant"
        :key="variant"
        type="grow"
      ></b-spinner>
    </div>
  </div>
  <b-row>
    <b-col>
      <b-alert
        variant="danger"
        class="d-flex align-items-center bt-alert"
        :show="false"
        dismissible
      >
        <b-button variant="primary">
          <i class="mdi mdi-alert-octagon"></i>
        </b-button>
        A informational alert
      </b-alert>
    </b-col>
  </b-row>
  <b-row>
    <b-col v-if="userRole.access.includes(1)" id="fauji-node">
      <b-card>
        <b-card-body class="d-flex p-3">
          <h5 class="card-title mb-0">Fauji Direct</h5>
          <b-button size="sm" variant="info" class="ml-auto mr-2" @click="downloadImage('fauji-node')">Export</b-button>
          <b-button size="sm" variant="info">Email</b-button>
        </b-card-body>
        <b-badge class="mb-2 d-flex px-2" pill variant="primary"><h5 class="m-0">Revenue {{ Intl.NumberFormat().format(loadedData.snapshot[0].gross[0].gross) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="success"><h5 class="m-0">Profit {{ Intl.NumberFormat().format(loadedData.snapshot[0].profit[0].selling_price-loadedData.snapshot[0].profit[0].buying_price) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="secondary"><h5 class="m-0">Expenses {{ Intl.NumberFormat().format(loadedData.snapshot[0].expenses[0].debit-loadedData.snapshot[0].expenses[0].credit) }}</h5></b-badge>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.fauji"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span>
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col v-if="userRole.access.includes(2)" id="toyota-node">
      <b-card>
        <b-card-body class="d-flex p-3">
          <h5 class="card-title mb-0">Toyota Direct</h5>
          <b-button size="sm" variant="info" class="ml-auto mr-2" @click="downloadImage('toyota-node')">Export</b-button>
          <b-button size="sm" variant="info">Email</b-button>
        </b-card-body>
        <b-badge class="mb-2 d-flex px-2" pill variant="primary"><h5 class="m-0">Revenue {{ Intl.NumberFormat().format(loadedData.snapshot[1].gross[0].gross) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="success"><h5 class="m-0">Profit {{ Intl.NumberFormat().format(loadedData.snapshot[1].profit[0].selling_price-loadedData.snapshot[1].profit[0].buying_price-loadedData.snapshot[1].passon[0].passon) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="secondary"><h5 class="m-0">Expenses {{ Intl.NumberFormat().format(loadedData.snapshot[1].expenses[0].debit-loadedData.snapshot[1].expenses[0].credit) }}</h5></b-badge>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.toyota"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span>
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col v-if="userRole.access.includes(3)" id="molfix-node">
      <b-card>
        <b-card-body class="d-flex p-3">
          <h5 class="card-title mb-0">Molfix Direct</h5>
          <b-button size="sm" variant="info" class="ml-auto mr-2" @click="downloadImage('molfix-node')">Export</b-button>
          <b-button size="sm" variant="info">Email</b-button>
        </b-card-body>
        <b-badge class="mb-2 d-flex px-2" pill variant="primary"><h5 class="m-0">Revenue {{ Intl.NumberFormat().format(loadedData.snapshot[2].gross[0].gross) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="success"><h5 class="m-0">Profit {{ Intl.NumberFormat().format(loadedData.snapshot[2].profit[0].selling_price-loadedData.snapshot[2].profit[0].buying_price-loadedData.snapshot[2].passon[0].passon) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="secondary"><h5 class="m-0">Expenses {{ Intl.NumberFormat().format(loadedData.snapshot[2].expenses[0].debit-loadedData.snapshot[2].expenses[0].credit) }}</h5></b-badge>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.molfix"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span>
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col v-if="userRole.access.includes(4)" id="tapal-node">
      <b-card>
        <b-card-body class="d-flex p-3">
          <h5 class="card-title mb-0">Tapal Direct</h5>
          <b-button size="sm" variant="info" class="ml-auto mr-2" @click="downloadImage('tapal-node')">Export</b-button>
          <b-button size="sm" variant="info">Email</b-button>
        </b-card-body>
        <b-badge class="mb-2 d-flex px-2" pill variant="primary"><h5 class="m-0">Revenue {{ Intl.NumberFormat().format(loadedData.snapshot[3].gross[0].gross) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="success"><h5 class="m-0">Profit {{ Intl.NumberFormat().format(loadedData.snapshot[3].profit[0].selling_price-loadedData.snapshot[3].profit[0].buying_price-loadedData.snapshot[3].passon[0].passon) }}</h5></b-badge>
        <b-badge class="mb-2 d-flex px-2" pill variant="secondary"><h5 class="m-0">Expenses {{ Intl.NumberFormat().format(loadedData.snapshot[3].expenses[0].debit-loadedData.snapshot[3].expenses[0].credit) }}</h5></b-badge>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.tapal"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span style="max-width:50%">
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="mt-4" id="circles-node">
    <b-col>
      <b-card>
        <b-card-body class="d-flex p-3">
          <h5 class="card-title mb-0">Circles Data</h5>
          <b-button size="sm" variant="info" class="ml-auto mr-2" @click="downloadImage('circles-node')">Export</b-button>
          <b-button size="sm" variant="info">Email</b-button>
        </b-card-body>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.circles"
        class="contact-table"
        >
          <template #cell(lockCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.lockCircle) }}
            </span>
          </template>
          <template #cell(currentCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.currentCircle) }}
            </span>
          </template>
          <template #cell(mdTarnol)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.mdTarnol) }}
            </span>
          </template>
          <template #cell(directInvenstment)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.directInvenstment) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</div>  
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import html2canvas from 'html2canvas';
import NProgress from "nprogress";
export default {
  name: "combine-dashboard",
  data: () => ({
    itemsFauji: [],
    itemsToyota: [],
    itemsMolfix: [],
    itemsTapal: [],
    itemsCircle: [],
    salesSnapshot: [],
    dashboardData: {
      faujiTotal: 0,
      toyotaTotal: 0,
      molfixTotal: 0,
      tapalTotal: 0,
      mdtarnolFauji: 0,
      mdtarnolToyota: 0,
      mdtarnolMolfix: 0,
      mdtarnolTapal: 0
    },
    fauji:[2,53,51,3,27,16,17,37,21,47,14,38,43,49,15],
    toyota:[53,53,93,54,5,56,65,67,55,94,66,98],
    molfix:[7,51,48,1,31,35,11,17,30,10,28,37,38,39,40,45,47,49],
    tapal:[44,55,59,3,36,49,15,19,26,43,38,56,54],
    cutOfDate: null,
    dates: {
      start: null,
      end: null,
      codate: null
    },
    pageReady:false, 
    variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'dark']
      
  }),
  computed: {
      ...mapState({
        faujiCircle: state => state.dashboard.fauji,
        toyotaCircle: state => state.dashboard.toyota,
        molfixCircle: state => state.dashboard.molfix,
        tapalCircle: state => state.dashboard.tapal,
        codate: state => state.dashboard.codate,
        loadedData: state => state.dashboard.loadedData,
        refreshState: state => state.dashboard.refreshState,
        userRole: state => state.auth.authData
      }),
  },
  watch: {
    refreshState(val){
      if(val){
        this.initDashboard();
      }
    }
  },
  async mounted(){
    if(!this.userRole){
      if(localStorage.getItem('token')){
        let roles = localStorage.getItem('access').split(',');
        roles = roles.map(r => {
          return parseInt(r);
        });
        this.saveAuthData({username: localStorage.getItem('user'), access: roles});
      }
      else{
        this.saveAuthData(null);
        this.$router.push({path: "/"});
      }
    }
    if(!this.loadedData){
      await this.initDashboard();
    }
    else{
      this.pageReady = true;
    }
  },
  methods:{
    ...mapActions(["reloadData", "saveAuthData", "saveLoadedData", "getBadEnteries", "getSalesSnapshot", "getCircles", "getFauji", "getToyota", "getMolfix", "getTapal", "getStockValue", "getPendingOrders", "getSupplierBalance", "getDiscounts", "getAutoDiscounts", "getShopsCredit", "getSales", "getPurchase", "getSalesmanCredit", "getLedger"]),
    formatTitle(text){
      if(text && text.includes(":")){
        return text.substr(0,15);
      }
      else{
        return text;
      }
    },
    downloadImage(mynode){
      html2canvas(document.getElementById(mynode)).then(function(canvas) {
        document.body.appendChild(canvas);
        const img = canvas.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = "md-snapshot.png";
        link.href = img;
        link.click();
      });
    },
    getFirstDayOfMonth(dt){
      return moment(dt).startOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    getLastDayOfMonth(dt){
      return moment(dt).endOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    async initDates(){
      return new Promise((resolve) => {
        NProgress.start();
        this.getCircles().then(() => {
          this.dates.start = this.getFirstDayOfMonth(this.codate);
          this.dates.end = this.getLastDayOfMonth(this.codate);
          this.dates.codate = this.codate;
          resolve();
        })
      });
    },
    async initLedgers(){
      return new Promise((resolve) => {
        this.getLedger({id:1,ledger:4,dates:this.dates}).then(async l => {
          this.dashboardData.mdtarnolFauji = l[0].debit - l[0].credit;
          await this.timeOut(1000);
          this.getLedger({id:2,ledger:14,dates:this.dates}).then(async m => {
            this.dashboardData.mdtarnolToyota = m[0].debit - m[0].credit;
            await this.timeOut(1000);
            this.getLedger({id:3,ledger:8,dates:this.dates}).then(async n => {
              this.dashboardData.mdtarnolMolfix = n[0].debit - n[0].credit;
              await this.timeOut(1000);
              this.getLedger({id:4,ledger:14,dates:this.dates}).then(o => {
                this.dashboardData.mdtarnolTapal = o[0].debit - o[0].credit;
                resolve();
              })
            })
          })
        })
      })
    },
    async initSnapshot(){
      return new Promise((resolve) => {
        this.getSalesSnapshot({id:1,dates:this.dates}).then(async fss => {
          await this.timeOut(1000);
          this.salesSnapshot.push(fss);
          this.getSalesSnapshot({id:2,dates:this.dates}).then(async lss => {
            await this.timeOut(1000);
            this.salesSnapshot.push(lss);
            this.getSalesSnapshot({id:3,dates:this.dates}).then(async mss => {
              await this.timeOut(1000);
              this.salesSnapshot.push(mss);
              this.getSalesSnapshot({id:4,dates:this.dates}).then(tss => {
                this.salesSnapshot.push(tss);
                resolve();
              });
            });
          });
        });
      })
    },
    initCircles(){
      return new Promise((resolve) => {
        if(this.userRole.access.includes(1)){
          this.itemsCircle.push({
            business: "Fauji Direct",
            lockCircle: this.faujiCircle,
            currentCircle: this.dashboardData.faujiTotal,
            mdTarnol: this.dashboardData.mdtarnolFauji,
            directInvenstment: this.dashboardData.faujiTotal + this.dashboardData.mdtarnolFauji 
          });
        }
        if(this.userRole.access.includes(2)){
          this.itemsCircle.push({
            business: "Toyota Direct",
            lockCircle: this.toyotaCircle,
            currentCircle: this.dashboardData.toyotaTotal,
            mdTarnol: this.dashboardData.mdtarnolToyota,
            directInvenstment: this.dashboardData.toyotaTotal + this.dashboardData.mdtarnolToyota 
          });
        }
        if(this.userRole.access.includes(3)){
          this.itemsCircle.push({
            business: "Molfix Direct",
            lockCircle: this.molfixCircle,
            currentCircle: this.dashboardData.molfixTotal,
            mdTarnol: this.dashboardData.mdtarnolMolfix,
            directInvenstment: this.dashboardData.molfixTotal + this.dashboardData.mdtarnolMolfix 
          });
        }
        if(this.userRole.access.includes(4)){
          this.itemsCircle.push({
            business: "Tapal Direct",
            lockCircle: this.tapalCircle,
            currentCircle: this.dashboardData.tapalTotal,
            mdTarnol: this.dashboardData.mdtarnolTapal,
            directInvenstment: this.dashboardData.tapalTotal + this.dashboardData.mdtarnolTapal 
          });
        }
        this.itemsCircle.push({
            business: "Totals",
            lockCircle: _.sumBy(this.itemsCircle, 'lockCircle'),
            currentCircle: _.sumBy(this.itemsCircle, 'currentCircle'),
            mdTarnol: _.sumBy(this.itemsCircle, 'mdTarnol'),
            directInvenstment: _.sumBy(this.itemsCircle, 'directInvenstment') 
        });
        this.saveLoadedData({
          fauji: this.itemsFauji,
          toyota: this.itemsToyota,
          molfix: this.itemsMolfix,
          tapal: this.itemsTapal,
          circles: this.itemsCircle,
          snapshot: this.salesSnapshot
        });
        this.pageReady = true;
        this.reloadData(false);
        NProgress.done();
        resolve();
      })
    },
    async prepareFauji(){
      return new Promise((resolve) => {
        this.getFauji({ids: this.fauji,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:1,dates:this.dates}).then(async stock => {
            this.itemsFauji.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:1,dates:this.dates}).then(async p => {
              this.itemsFauji.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:1,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:1,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsFauji.push({"head_title": s.supplier_name, "balance": l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    this.getShopsCredit({id:1,dates:this.dates}).then(async shop => {
                      this.itemsFauji.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:1,dates:this.dates}).then(async sale => {
                        await this.timeOut(1000);
                        this.getPurchase({id:1,dates:this.dates}).then(async purchase => {
                        await this.timeOut(1000);  
                        this.getSalesmanCredit({id:1,dates:this.dates}).then(async credit => {
                          this.itemsFauji.push({"head_title": "Salesman Credit", "balance":(sale[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:1,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getLedger({id:1, type:"discounts", ledger:[5,6,7,8,9,11,12], dates:this.dates}).then(async dl => {
                            this.itemsFauji.push({"head_title": "Discounts", "balance":(disc[0].discounts + dl[0].debit) - dl[0].credit});
                            await this.timeOut(1000);
                            this.getLedger({id:1, type:"discounts", ledger:[10], dates:this.dates}).then(fmr => {
                            this.itemsFauji.push({"head_title": "FMR", "balance":(disc[0].fmr + fmr[0].debit) - (fmr[0].credit + purchase[0].fmr)});
                            fdata.forEach((el, n) => {
                              this.itemsFauji.push(el);
                              if(fdata.length === n+1){
                                this.dashboardData.faujiTotal = _.sumBy(this.itemsFauji, function(o) { return o.balance; });
                                this.itemsFauji.push({"head_title": "Total", "balance": this.dashboardData.faujiTotal});
                                this.itemsFauji.push({"head_title": "Circle", "balance": this.faujiCircle});
                                this.itemsFauji.push({"head_title": "Diff", "balance": this.dashboardData.faujiTotal - this.faujiCircle});
                                resolve();
                              }
                            });
                            });
                            });
                          });
                        });
                        });
                      });
                    });
                  }
                })
              });
            });
          });
        });
      });
    },
    async prepareToyota(){
      return new Promise((resolve) => {
        this.getToyota({ids: this.toyota,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:2,dates:this.dates}).then(async stock => {
            this.itemsToyota.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:2,dates:this.dates}).then(async p => {
              this.itemsToyota.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:2,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:2,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsToyota.push({"head_title": s.supplier_name, "balance":l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    this.getShopsCredit({id:2,dates:this.dates}).then(async shop => {
                      this.itemsToyota.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:2,dates:this.dates}).then(async sale => {
                        await this.timeOut(1000);
                        this.getSalesmanCredit({id:2,dates:this.dates}).then(async credit => {
                          this.itemsToyota.push({"head_title": "Salesman Credit", "balance":(sale[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:2,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getLedger({id:2, type:"discounts", ledger:[4,6,7,8,10,11,13], dates:this.dates}).then(dl => {
                            this.itemsToyota.push({"head_title": "Discounts", "balance":(disc[0].discounts + dl[0].debit) - dl[0].credit});
                            fdata.forEach((el, n) => {
                              this.itemsToyota.push(el);
                              if(fdata.length === n+1){
                                this.dashboardData.toyotaTotal = _.sumBy(this.itemsToyota, function(o) { return o.balance; });
                                this.itemsToyota.push({"head_title": "Total", "balance": this.dashboardData.toyotaTotal});
                                this.itemsToyota.push({"head_title": "Circle", "balance": this.toyotaCircle});
                                this.itemsToyota.push({"head_title": "Diff", "balance": this.dashboardData.toyotaTotal - this.toyotaCircle});
                                resolve();
                              }
                            });
                            });
                          });
                        });
                      });
                    });
                  }
                })
              });
            });
          });
        });
      })
    },
    async prepareMolfix(){
      return new Promise((resolve) => {
        this.getMolfix({ids: this.molfix,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:3,dates:this.dates}).then(async stock => {
            this.itemsMolfix.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:3,dates:this.dates}).then(async p => {
              this.itemsMolfix.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:3,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:3,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsMolfix.push({"head_title": s.supplier_name, "balance":l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    this.getShopsCredit({id:3,dates:this.dates}).then(async shop => {
                      this.itemsMolfix.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:3,dates:this.dates}).then(async sale => {
                        const allsales = sale;
                        await this.timeOut(1000);
                        this.getPurchase({id:3,dates:this.dates}).then(async purchase => {
                        await this.timeOut(1000);
                        this.getSalesmanCredit({id:3,dates:this.dates}).then(async credit => {
                          this.itemsMolfix.push({"head_title": "Salesman Credit", "balance":(allsales[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:3,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getAutoDiscounts({id:3, type: "sale",ledger: [3,4,5], dates:this.dates}).then(async autodisc => {
                              await this.timeOut(1000);
                              this.getLedger({id:3, type:"discounts", ledger:[3,4,5,12,14], dates:this.dates}).then(async dl => {
                                this.itemsMolfix.push({"head_title": "Discounts", "balance":(disc[0].discounts + autodisc[0].discounts + dl[0].debit) - dl[0].credit});
                                await this.timeOut(1000);
                                this.getAutoDiscounts({id:3, type: "purchase",ledger: [6], dates:this.dates}).then(async autodiscp => {
                                  await this.timeOut(1000);
                                  this.getLedger({id:3, type:"discounts", ledger: 6, dates:this.dates}).then(async dlp => {
                                    this.itemsMolfix.push({"head_title": "Discount on purchase", "balance":dlp[0].debit - (autodiscp[0].discounts + dlp[0].credit)});
                                    await this.timeOut(1000);
                                    this.getLedger({id:3,ledger:15,dates:this.dates}).then(async st => {
                                      this.itemsMolfix.push({"head_title": st[0].head_title, "balance": (st[0].debit + purchase[0].salestax) - (allsales[0].stax + st[0].credit)});
                                      await this.timeOut(1000);
                                      this.getLedger({id:3,ledger:16,dates:this.dates}).then(ft => {
                                        this.itemsMolfix.push({"head_title": ft[0].head_title, "balance":  ft[0].debit - (allsales[0].ftax + ft[0].credit)});
                                        fdata.forEach((el, n) => {
                                          this.itemsMolfix.push(el);
                                          if(fdata.length === n+1){
                                            this.dashboardData.molfixTotal = _.sumBy(this.itemsMolfix, function(o) { return o.balance; });
                                            this.itemsMolfix.push({"head_title": "Total", "balance": this.dashboardData.molfixTotal});
                                            this.itemsMolfix.push({"head_title": "Circle", "balance": this.molfixCircle});
                                            this.itemsMolfix.push({"head_title": "Diff", "balance": this.dashboardData.molfixTotal - this.molfixCircle});
                                            resolve();
                                          }
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                        });
                      })
                    });
                  }
                })
              });
            });
          });
        });
      })
    },
    async prepareTapal(){
      return new Promise((resolve) => {
        this.getTapal({ids: this.tapal,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:4,dates:this.dates}).then(async stock => {
            this.itemsTapal.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:4,dates:this.dates}).then(async p => {
              this.itemsTapal.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:4,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:4,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsTapal.push({"head_title": s.supplier_name, "balance":l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    this.getShopsCredit({id:4,dates:this.dates}).then(async shop => {
                      this.itemsTapal.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:4,dates:this.dates}).then(async sale => {
                        await this.timeOut(1000);
                        this.getPurchase({id:4,dates:this.dates}).then(async purchase => {
                        await this.timeOut(1000);
                        this.getSalesmanCredit({id:4,dates:this.dates}).then(async credit => {
                          this.itemsTapal.push({"head_title": "Salesman Credit", "balance":(sale[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:4,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getLedger({id:4, type:"discounts", ledger:[5,6,7,8,9,10,11,13], dates:this.dates}).then(async dl => {
                            this.itemsTapal.push({"head_title": "Discounts", "balance":(disc[0].discounts + dl[0].debit) - dl[0].credit});
                            await this.timeOut(1000);
                            this.getLedger({id:4, ledger:[12], dates:this.dates}).then(async dll => {
                            this.itemsTapal.push({"head_title": dll[0].head_title, "balance": dll[0].debit - (purchase[0].tradedisc + dll[0].credit)});
                            await this.timeOut(1000);
                            fdata.forEach(async (el, n) => {
                              this.itemsTapal.push(el);
                              if(fdata.length === n+1){
                                this.dashboardData.tapalTotal = _.sumBy(this.itemsTapal, function(o) { return o.balance; });
                                this.itemsTapal.push({"head_title": "Total", "balance": this.dashboardData.tapalTotal});
                                this.itemsTapal.push({"head_title": "Circle", "balance": this.tapalCircle});
                                this.itemsTapal.push({"head_title": "Diff", "balance": this.dashboardData.tapalTotal - this.tapalCircle});
                                resolve();
                              }
                            });
                            });
                            });
                          });
                        });
                        });
                      });
                    });
                  }
                })
              });
            });
          });
        });
      })
    },
    async timeOut(interval){
      return new Promise((resolve) => {
        setTimeout(() => {  resolve(); }, interval);
      })
    },
    async initDashboard(){
      await this.initDates();
      await this.initSnapshot();
      await this.initLedgers();
      await this.prepareFauji();
      await this.prepareToyota();
      await this.prepareMolfix();
      await this.prepareTapal();
      await this.initCircles();
    },
  }
};
</script>
