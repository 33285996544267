import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "/dashboard/combine-dashboard",
      component: () => import("@/layouts/full-layout/FullLayout"),
      meta: {
        public: false
      },
      children: [
        // dashboard
        {
          name: "Combined Dashboard",
          path: "/dashboard/combine-dashboard",
          component: () =>
            import("@/views/dashboards/combine-dashboard/CombineDashboard"),
          meta: {
            public: false
          }
        },
        {
          name: "Fauji Dashboard",
          path: "/dashboard/fauji-dashboard",
          component: () =>
            import("@/views/dashboards/fauji-dashboard/FaujiDashboard"),
          meta: {
            public: false
          }
        },
        {
          name: "Toyota Dashboard",
          path: "/dashboard/toyota-dashboard",
          component: () =>
            import("@/views/dashboards/toyota-dashboard/ToyotaDashboard"),
          meta: {
            public: false
          }
        },
        {
          name: "Molfix Dashboard",
          path: "/dashboard/molfix-dashboard",
          component: () =>
            import("@/views/dashboards/molfix-dashboard/MolfixDashboard"),
          meta: {
            public: false
          }
        },
        {
          name: "Tapal Dashboard",
          path: "/dashboard/tapal-dashboard",
          component: () =>
            import("@/views/dashboards/tapal-dashboard/TapalDashboard"),
          meta: {
            public: false
          }
        },
        {
          name: "Dashboard Configuration",
          path: "/dashboard/settings",
          component: () =>
            import("@/views/Settings"),
          meta: {
            public: false
          }
        }
      ],
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      meta: {
        public: true
      },
      children: [
        {
          name: "BoxedLogin",
          path: "/authentication/boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
          meta: {
            public: true
          }
        }
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
      meta: {
        public: true
      }
    },
  ],
});

import NProgress from "nprogress";

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  //if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  //}
  if (to.matched.some(record => record.meta.public)) {
      next({
          params: {
              nextUrl: to.fullPath
          }
      });
  } else {
    if (localStorage.getItem("token") === null || localStorage.getItem("token") === 'null') {
      console.log(localStorage.getItem("token"));
      next({
            path: "authentication/boxedlogin",
            params: {
                nextUrl: to.fullPath
            }
      });
    } else {
        next();
    }
  }
  //next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
