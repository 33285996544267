import Axios from "axios";
export default({
    state: {
      fauji: null,
      toyota: null,
      molfix: null,
      tapal: null,
      codate: null,
      loadedData: null,
      refreshState: false
    },
    mutations: {
      SET_FAUJI(state, payload){
        state.fauji = payload;
      },
      SET_TOYOTA(state, payload){
        state.toyota = payload;
      },
      SET_MOLFIX(state, payload){
        state.molfix = payload;
      },
      SET_TAPAL(state, payload){
        state.tapal = payload;
      },
      SET_CIRCLES(state, payload){
        state.fauji = payload.fauji;
        state.toyota = payload.toyota;
        state.molfix = payload.molfix;
        state.tapal = payload.tapal;
        state.codate = payload.date;
      },
      SET_DATA(state, payload){
        state.loadedData = payload;
      },
      SET_REFRESH(state, payload){
        state.refreshState = payload;
      }
    },
    actions: {
      saveLoadedData({commit}, payload) {
        commit('SET_DATA', payload);
      },
      reloadData({commit}, payload) {
        commit('SET_REFRESH', payload);
      },
      saveCircles({ dispatch }, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/circles", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            dispatch("getCircles");
            return res.data;
        })
      },
      getCircles({commit}) {
        return Axios.get(process.env.VUE_APP_API_URL+"/dashboard/circles",
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            commit('SET_CIRCLES', res.data[0])
            return res.data;
        })
      }, 
      getFauji(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/fauji", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getToyota(_, payload) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/toyota", payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getMolfix(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/molfix", paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getTapal(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/tapal", paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getPendingOrders(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/pendingorders",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getSupplierBalance(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/suppliersbalance",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getDiscounts(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/discounts",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getAutoDiscounts(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/autodiscounts",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getShopsCredit(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/shopscredit",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getSalesmanCredit(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/salesmancredit",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getSales(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/sales",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getPurchase(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/purchase",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getLedger(_, paylaod) {
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/ledger",paylaod,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getStockValue(_, payload){
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/stock",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getBadEnteries(_, payload){
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/badenteries",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      },
      getSalesSnapshot(_, payload){
        return Axios.post(process.env.VUE_APP_API_URL+"/dashboard/salessnapshot",payload,
        {
          headers: { "Content-type": "application/json" }
        })
        .then((res) => {
            return res.data;
        })
      }
    },
    getters: {},
  });